<template>
  <div>
    <base-header type="gradient-blue" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div id="my-account" class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">
                    {{
                      action == "new"
                        ? "Nueva ruta"
                        : "Información de la ruta"
                    }}
                  </h3>
                </div>
              </div>
            </div>
            <template>
              <form @submit.prevent>
                <h6 class="heading-small text-muted mb-4">
                  Información General
                </h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-6">
                      <base-input
                        :required="true"
                        alternative=""
                        label="Nombre de la ruta"
                        placeholder="Nombre de la ruta"
                        input-classes="form-control-alternative"
                        v-model="model.route.name"
                      />
                    </div>
                    <div class="col-lg-6">
                      <label class="form-control-label">
                        Agente asignado
                      </label>
                      <select
                        name="agent"
                        class="form-control"
                        v-model="model.route.agent_id"
                      >
                        <option
                          v-for="item in agents"
                          v-bind:key="item.id"
                          :value="item.id"
                        >
                          {{ item.nombre }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-4">
                      <base-button v-on:click="saveRoute" type="primary"
                        >Guardar</base-button
                      >
                    </div>
                  </div>
                </div>
                <hr class="my-4" />
                <!-- Address -->
                <h6 class="heading-small text-muted mb-4">Selecciona un día</h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div v-for="day in model.days" :key="day.id"  class="col-4  col-lg-3 col-xl-2">
                      <div v-on:click="selectDay(day)" 
                          :class="selectedDay == day.id ? 'bg-primary text-white' : 'bg-secondary'"
                          class="card card shadow mb-2" 
                          style="border: solid 1px #ccc; cursor: pointer;">
                        <div class="card-body text-center">
                          <strong style="text-transform: capitalize;">{{ day.spanish_name }}</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="loadingClients" class="row mt-4">
                    <div class="col-12">
                      <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                          <div class="row">
                            <div class="col-6">
                              <h3 class="mb-0">
                                <img alt="Excel" width="40" :src="`${publicPath}img/theme/excel.png`">
                                Cargar con Excel          
                              </h3>
                            </div>
                            <div class="col-6 text-right">
                              <i v-on:click="excelClose" class="ni ni-fat-remove" style="font-size: 1.9rem; cursor: pointer"></i>
                            </div>
                          </div>
                        </div>
                        <template>
                          <div class="row">
                            <div class="col-6">
                              <input v-if="this.clients.length > 0"
                                id="excelFile"
                                type="file"
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                alternative=""
                                label="Selecciona el archivo"
                                placeholder="Selecciona el archivo"
                                class="form-control"
                              />
                              <div v-else class="spinner-border text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>
                            </div>
                            <div class="col-6 text-right">
                              <base-button type="success" v-on:click="excelLoader">Cargar</base-button>
                            </div>
                          </div>
                        </template>
                      </card>
                    </div>
                  </div>
                  <div v-if="selectedDay > 0" class="row mt-4">
                    <div class="col-12">
                      <clients-table ref="clientsTable"
                        v-on:sorted="sorted"
                        v-on:new="newClient" 
                        v-on:load="loadClients" 
                        v-on:delete="deleteClient"
                        title="Clientes" 
                        :dayId="selectedDay" ></clients-table>
                    </div>
                  </div>
                </div>
              </form>
            </template>
          </card>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <base-alert
            ref="alert"
            :type="alert.type"
            :dismissible="true"
            :icon="alert.icon"
          >
            <template slot="text">{{ alert.message }}</template>
          </base-alert>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseAlert from "../components/BaseAlert.vue";
import ClientsTable from "./Tables/ClientsTable.vue";
import httpMixin from "../mixins/httpMixin";
import xlsx from "read-excel-file";
export default {
  components: { BaseAlert, ClientsTable },
  name: "route",
  data() {
    return {
      publicPath: process.env.BASE_URL,
      action: "",
      alert: {
        type: "default",
        message: "",
        icon: ""
      },
      routeId: 0,
      model: {
        route: {
          name: '',
          agent_id: 0
        },
        days: []
      },
      routeDay: {},
      selectedDay: 0,
      agents: [],
      clients: [],
      newClients: [],
      loadingClients: false,
    };
  },
  mounted() {
    if (this.$route.params.id == "nuevo") {
      this.action = "new";
    } else {
      this.action = "edit";
      this.fetchRouteInfo();
    }
    this.fetchAgentsInfo();
  },
  methods: {
    fetchRouteInfo: async function() {
      this.httpGet("/routes/" + this.$route.params.id)
        .then(res => {
          this.model.route = res.data['route'][0];
          this.model.days = res.data['days'];
        })
        .catch(err => {
          console.log(err);
        });
    },
    fetchAgentsInfo: async function() {
      this.httpGet("/agents")
        .then(res => {
            res.data.forEach(agent => {
                this.agents.push({
                    id: agent['id'],
                    nombre: agent['name'],
                    vendedor: agent['ms_vendedor_id'],
                    cobrador: agent['ms_cobrador_id']
                })
            });
        })
        .catch(err => {
          console.log(err);
        });
    },
    selectDay: function(day) {
      this.selectedDay = day.id
    },
    saveRoute: function() {
      if (this.action == "new") {
        this.httpPost("/routes", this.model)
          .then(res => {
            this.alert.type = "success";
            this.alert.message = "Ruta guardada correctamente";
            this.alert.icon = "ni ni-like-2";
            this.$refs.alert.alertCreated();
            setTimeout(() => {
              this.$router.push({ path: '/rutas' })
            }, 3000);
          })
          .catch(err => {
            this.alert.type = "danger";
            this.alert.message = err.message;
            this.alert.icon = "ni ni-notification-70";
            this.$refs.alert.alertCreated();
          });
      } else {
        this.httpPut("/routes", this.model)
          .then(res => {
            this.alert.type = "success";
            this.alert.message = "Ruta guardada correctamente";
            this.alert.icon = "ni ni-like-2";
            this.$refs.alert.alertCreated();
          })
          .catch(err => {
            this.alert.type = "danger";
            this.alert.message = err.message;
            this.alert.icon = "ni ni-notification-70";
            this.$refs.alert.alertCreated();
          });
      }
    },
    newClient: function () {},
    loadClients: function () {
      this.loadingClients = true
      if(this.clients.length <= 0 ) this.getClients();
    },
    getClients: function () {
      this.httpGet("/sync/get/clientes")
        .then(res => {
          this.clients = res.data
        })
        .catch(err => {
          console.log(err);
        });
    },
    excelClose: function () {
      this.loadingClients = false
    },
    excelLoader: async function () {
      const input = document.getElementById("excelFile");
      var loadedClients = []
      if (input.files.length > 0) {
        await xlsx(input.files[0]).then(async (rows) => {
          await rows.forEach((row, i) => {
            if (i > 0) {
              const key = row[0]              
              const found = this.clients.find(ele => ele.CL == key)
              if ( typeof found !== 'undefined' && (found.VD == this.getAgent.vendedor || found.CD == this.getAgent.cobrador)) {
                const newClient = {
                  id: found.ID,
                  key: found.CL,
                  name: found.NM,
                  address: found.DIR
                }
                loadedClients.push(newClient)
              }
            }
          });
          this.postClients(loadedClients)
        });
      }
    },
    postClients: function (newClients) {
      this.httpPost("/routes/clients", {routeDay: this.selectedDay,  clients: newClients})
          .then(res => {
            this.$refs.clientsTable.refresh()
            this.loadingClients = false
            this.alert.type = "success";
            this.alert.message = "Clientes guardados correctamente";
            this.alert.icon = "ni ni-like-2";
            this.$refs.alert.alertCreated();
          })
          .catch(err => {
            this.alert.type = "danger";
            this.alert.message = err.message;
            this.alert.icon = "ni ni-notification-70";
            this.$refs.alert.alertCreated();
          });
    },
    sorted: function(clients) {
      this.httpPut("/routes/clients/sort", {routeDay: this.selectedDay, clients: clients})
          .then(res => {
            this.$refs.clientsTable.refresh()
            this.loadingClients = false
            this.alert.type = "success";
            this.alert.message = "Clientes ordenados correctamente";
            this.alert.icon = "ni ni-like-2";
            this.$refs.alert.alertCreated();
          })
          .catch(err => {
            this.alert.type = "danger";
            this.alert.message = err.message;
            this.alert.icon = "ni ni-notification-70";
            this.$refs.alert.alertCreated();
          });
    },
    deleteClient: function(row) {
      this.httpDelete("/routes/clients/" + row.id)
          .then(res => {
            this.$refs.clientsTable.refresh()
            console.log(res)
            this.loadingClients = false
            this.alert.type = "success";
            this.alert.message = "Cliente eliminado correctamente";
            this.alert.icon = "ni ni-like-2";
            this.$refs.alert.alertCreated();
          })
          .catch(err => {
            this.alert.type = "danger";
            this.alert.message = err.message;
            this.alert.icon = "ni ni-notification-70";
            this.$refs.alert.alertCreated();
          });
    }
  },
  computed: {
    getAgent: function() {
      return this.agents.find(ele => ele.id == this.model.route.agent_id)
    }
  },
  mixins: [httpMixin]
};
</script>
<style></style>
