<template>
  <div class="card shadow"
       :class="type === 'dark' ? 'bg-default': ''">
    <div class="card-header border-0"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col-7">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            {{title}} | {{clients.length}}
          </h3>
        </div>
        <div class="col-5 text-right">
          <base-button v-on:click="loadClients" type="success">Cargar con excel</base-button>
        </div>
        
      </div>
    </div>

    <div class="table-responsive" style="min-height:250px;">
      <base-table class="table align-items-center table-flush"
                  :class="type === 'dark' ? 'table-dark': ''"
                  :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                  tbody-classes="list"
                  :data="clients"
                  :drag="true"
                  v-on:dragged="newSort"
                  >
        <template slot="columns">
          <th>Clave</th>
          <th>Nombre</th>
          <th>Dirección</th>
          <th>Estatus</th>
          <th>Acciones</th>
        </template>
        <template slot-scope="{row}">
          <th scope="row">
            <div class="media align-items-center">
              <a href="#" class="avatar rounded-circle mr-3">
                <img alt="Imagen usuario" :src="`${publicPath}img/theme/user-profile-default.jpg`">
              </a>
              <div class="media-body">
                <span class="name mb-0 text-sm">{{row.client_key}}</span>
              </div>
            </div>
          </th>
          <td class="budget">
            {{
              row.client_name ? row.client_name.substring(0,40) : ''
            }}
          </td>
          <td class="budget">
            {{
              row.client_address ? row.client_address.substring(0,40) : ''
            }}
          </td>
          <td>
            <badge class="badge-dot mr-4" :type="convertStatusToColor(row.status)">
              <i :class="`bg-${convertStatusToColor(row.status)}`"></i>
              <span class="status">{{convertStatusToName(row.status)}}</span>
            </badge>
          </td>
          <td class="text-left">
            <base-dropdown class="dropdown"
                           position="right">
              <a slot="title" class="btn btn-sm btn-icon-only text-light" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-ellipsis-v"></i>
              </a>

              <template>
                <span v-if="row.status === 'A'" class="dropdown-item" v-on:click="updateStatus(row, 'I')" >Inactivar</span>
                <span v-if="row.status === 'I'" class="dropdown-item" v-on:click="updateStatus(row, 'A')" >Activar</span>
                <span v-on:click="deleteRow(row)" class="dropdown-item" >Eliminar</span>
              </template>
            </base-dropdown>
          </td>

        </template>

      </base-table>
    </div>
  <!--
    <div class="card-footer d-flex justify-content-end"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <base-pagination :total="30"></base-pagination>
    </div>
  -->
  </div>
</template>
<script>
import httpMixin from '../../mixins/httpMixin'
  export default {
    name: 'clients-table',
    props: {
      type: {
        type: String
      },
      title: String,
      dayId: Number
    },
    data() {
      return {
        clients: [],
        publicPath: process.env.BASE_URL
      }
    },
    mounted() {
      this.fetchClientsInfo()
    },
    watch: {
        dayId(newValue, oldValue) {
            this.dayId = newValue
            this.fetchClientsInfo()
        }
    },
    methods: {
      fetchClientsInfo: function () {
        this.httpGet('/routes/day/' + this.dayId)
          .then(res => {
            this.clients = res.data['days'];
          })
          .catch(err => {
            console.log(err)
          })
      },
      refresh: function() {
        this.fetchClientsInfo()
      },
      loadClients: function () {
          this.$emit('load');
      },
      newClient: function () {
          this.$emit('new');
      },
      newSort: function (clients) {
        this.$emit('sorted', clients)
      },
      updateStatus: async function (row, status) {
        row.status = status
        this.httpPut("/routes/clients", {row: row})
          .then((res) => {
            this.refresh()
          })
          .catch((err) => {
            console.log(err)
          });
      },
      deleteRow: function(row) {
        this.$emit('delete', row)
      },
      convertStatusToColor (status) {
        if (status == 'A') {
          return 'success'
        } else if (status == 'I') {
          return 'danger'
        }
      },
      convertStatusToName (status) {
        if (status == 'A') {
          return 'Activa'
        } else if (status == 'I') {
          return 'Inactiva'
        }
      },
    },
    mixins: [httpMixin]
  }
</script>
<style>
</style>
